<template>
  <div class="page">
    <!-- <navTop title="武夷商务" :leftIcon="false"></navTop> -->
    <div class="home">
      <div class="home-tab-box">
        <!-- <img src="../assets/imgs/tab_bg.png" class="home-tab-bg" /> -->
        <div class="home-tab">
          <div
            class="home-tab-item"
            :class="current == 1 ? 'home-tab-select' : ''"
            @click="setCurrent(1)"
          >
            武夷山市招商视频
            <div class="line1" v-if="current == 1"></div>
          </div>
          <div
            class="home-tab-item"
            :class="current == 2 ? 'home-tab-select' : ''"
            @click="setCurrent(2)"
          >
            武夷山市招商地图
            <div class="line2" v-if="current == 2"></div>
          </div>
        </div>

        <div class="home-tab-div" v-if="current == 2">
          <div class="map-text">武夷山 满眼是<span style="color: green;font-size: 24px;">绿</span> 遍地是<span style="color: gold;font-size: 24px;">金</span></div>
          <div class="enter-map">
            <img src="../assets/imgs/banner.png" class="home-tab-banner" />
            <div class="home-tab-info">
              <div class="home-tab-map"  @click="toMap">
                <img src="../assets/imgs/adress.png" class="home-tab-adress" />
                进入地图
              </div>
            </div>
          </div>
         
        </div>
        <div class="home-tab-div2" v-if="current == 1">
          <video class="video" :src="videoUrl"  height="auto"   width="100%" preload="metadata" controls></video>
        </div>
      </div>
      <!-- 底下的招商企业 -->
      <div class="business-bg">
        <div class="business-title">武夷山市招商重点区域及企业</div>
        <div :class="'business-min'+(index+1)" v-for="(item,index) in companyType" :key="index" @click="toCompany(item)">
          <div class="business-name">
            武夷山市{{item.name}}招商重点区域及企业
          </div>
          <div class="business-next">
            <van-icon name="arrow" color="#fff" size="13"/>
          </div>
        </div>
        <div class="box500">
          <div class="business-min-500" style="background:#D3C5F0;margin-right: 5px;" @click="toChina">
            <div class="business-name" style="color:#824CED">
              中国500强
            </div>
            <div class="business-next">
              <van-icon name="arrow" color="#fff" size="13"/>
            </div>
          </div>
          <div class="business-min-500" style="background:#F5DAD7;margin-left: 5px;" @click="toWorld">
            <div class="business-name"  style="color:#Fb5947">
              世界500强
            </div>
            <div class="business-next">
              <van-icon name="arrow" color="#fff" size="13"/>
            </div>
          </div>
        </div>
      </div>
     
      <!-- 招商情况模块 -->
      <div class="business-bg">
        <div class="business-title">武夷山市产业招商情况/区域招商情况</div>
        <div class="business-zhao">
          <div class="business-manage" @click="goBusinessPage(1,'manage')">
            <div class="business-manage-title">2023年</div>
            <div class="business-manage-title">
              武夷山市“一把手”招商攻坚项目动态
            </div>
            <div class="business-manage-title">管理手册</div>
          </div>

          <div class="business-manage-min">
            <div class="business-manage-half bg1" @click="goBusinessPage(5,'project')">
              <div class="business-manage-title">2023年武夷山市</div>
              <div class="business-manage-title">“一把手”招商考评老旧项目攻坚</div>
            </div>
            <div class="business-manage-half bg2" @click="goBusinessPage(4,'project')">
              <div class="business-manage-title">2022年已开工项目</div>
              <div class="business-manage-title">实际完成投资情况明细表</div>
            </div>
          </div>
           
          <div class="business-manage-min">
            <div class="business-manage-half bg3" @click="goBusinessPage(2,'business')">
              <div class="business-manage-title">武夷山市</div>
              <div class="business-manage-title">可招商项目</div>
            </div>
            <div class="business-manage-half bg4" @click="goBusinessPage(3,'history')">
              <div class="business-manage-title">武夷山市待解决</div>
              <div class="business-manage-title">历史遗留问题项目</div>
            </div>
          </div>
        


        </div>
      </div>

       <!-- 企业服务直通车 -->
       <div class="business-bg">
        <div class="business-title">企业服务直通车</div>
        <div class="car-box">
          <div class="car-min" @click="goHompage">
            悦进武夷
            <img src="../assets/imgs/car_book.png" class="car-icon" />
          </div>
          <router-link class="car-min" to="/live">
            武夷生活
            <img src="../assets/imgs/car_live.png" class="car-icon" />
          </router-link>
        </div>
        <div class="car-box">
          <div class="car-min" @click="goLaw">
            法律文件
            <img src="../assets/imgs/law.png" class="car-icon" />
          </div>
          <div class="car-min" @click="goDiscounts">
            优惠政策
            <img src="../assets/imgs/discount.png" class="car-icon" />
          </div>
          <!-- <router-link class="car-min" to="/propose">
            意向建议
            <img src="../assets/imgs/car_flie.png" class="car-icon" />
          </router-link> -->
        </div>
        <div class="car-box">
          <div class="car-min" @click="goSenver">
            商务服务
            <img src="../assets/imgs/car_sever.png" class="car-icon" />
          </div>
          <div class="car-min" @click="goContact">
            联系我们
            <img src="../assets/imgs/contact.png" class="car-icon" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import navTop from "@/components/common/navTop.vue";
import { getSiteVideo,getCompanyFirstCategory} from "@/api/index";
export default {
  name: "Home",
  components: {
    navTop,
  },
  data() {
    return {
      current: 1,
      videoUrl:'',
      companyType: [
    ]
    };
  },
  created(){
this.getSiteVideo();
this.getCompanyFirstCategory()
  },
  methods: {
    goBusinessPage(type,routeName){
      this.$router.push({
        path:'/'+routeName,
        query:{
          type:type,
        }
      })
    },
      // 跳转到company页面
    toCompany(item){
      this.$router.push({
        path:'/company',
        query:{
          keywords:item.keywords,
          id:item.id,
          title:'武夷山市'+item.name+'招商重点区域及企业'
        }
      })
    },
    toMap(){
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 500
      });
      this.$router.push({
        path:'/wymap'
      })
    },
    toChina(){
      location.href = 'https://mp.weixin.qq.com/s?__biz=MzA4ODAxOTg3MA==&mid=2247507057&idx=1&sn=75157e02e0ab117f76ca527109d09e44&chksm=90321a80a745939632f86f269325f4a8a54dbb9435d5eb4d13e480924a6c3e98c004aa41d134&token=1997316890&lang=zh_CN#rd'
    },
    toWorld(){
      location.href = 'https://mp.weixin.qq.com/s?__biz=MzA4ODAxOTg3MA==&mid=2247507052&idx=1&sn=5bbb8ae85a0034d4303f90b91107fa96&chksm=90321a9da745938bed9b5c498a7fa4be35c0e46a7edc9f5909889d9652d8b0f7e166f6736b83&token=1997316890&lang=zh_CN#rd'
    },
    //招商视频地址
     async getSiteVideo() {
      try {
        const { code, data } = await getSiteVideo();
        this.videoUrl = process.env.VUE_APP_API+ data.site_video
      } catch (e) {
        console.log(e);
      }
    },
    //重点招商企业产业链
    async getCompanyFirstCategory() {
      try {
        const { code, data } = await getCompanyFirstCategory();
        this.companyType = data;
      } catch (e) {
        console.log(e);
      }
    },
    setCurrent(num) {
      this.current = num;
    },
    goSenver(){
      location.href = 'http://mp.weixin.qq.com/mp/homepage?__biz=MzA4ODAxOTg3MA==&hid=6&sn=acf6e82f89625e4f81146b67fd092838&scene=18#wechat_redirect'
    },
    goDiscounts(){
      location.href = 'https://mp.weixin.qq.com/mp/homepage?__biz=MzA4ODAxOTg3MA==&hid=20&sn=94765007881d2e283714308ece934591&scene=18'
    },
    goLaw(){
      location.href = 'https://mp.weixin.qq.com/mp/homepage?__biz=MzA4ODAxOTg3MA==&hid=21&sn=e4395de24c9bd4502d6dcc9fb7784918&scene=18'
    },
    goContact(){
      location.href = 'http://mp.weixin.qq.com/mp/homepage?__biz=MzA4ODAxOTg3MA==&hid=3&sn=61f6f3a3a1f15a6259d47f499becfb3c&scene=18#wechat_redirect'
    },
    
    goHompage(){
      location.href = 'https://mp.weixin.qq.com/mp/homepage?__biz=MzA4ODAxOTg3MA==&hid=17&sn=55737343f35edf9c436d723ebc238efc&scene=18'
    }
  },
};
</script>
<style lang="scss" scoped>
.home-tab-box {
  position: relative;
  padding: 16px;
  background-image: url("../assets/imgs/tab_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
}
.home-tab-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}
.home-tab {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 10px;
}
.home-tab .home-tab-select {
  font-size: 17px;
}
.home-tab-item {
  flex: 1;
  position: relative;
}
.line1 {
  height: 3px;
  width: 100px;
  background-color: rgba(189, 215, 252, 0.4);
  position: absolute;
  left: 20px;
  bottom: 0px;
}
.line2 {
  height: 3px;
  width: 100px;
  background-color: rgba(63, 89, 255, 0.6);
  position: absolute;
  left: 20px;
  bottom: 0px;
}
.home-tab-div2 {
  // background-color: #fff;
  // border-radius: 10px;
  // padding: 20px 10px;
  // color: #333;
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  height: 198px;

}
.video{
  background-image: url('../assets/imgs/video_bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 198px;
  border-radius: 10px;
}
.home-tab-div {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px 10px;
  color: #333;
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  height: 158px;
}
.enter-map{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home-tab-banner {
  width: 140px;
  margin-left: 20px;
}
.home-tab-text {
  font-weight: 600;
  margin-bottom: 8px;
  font-size: 12px;
}
.home-tab-map {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  height: 30px;
  border-radius: 15px;
  width: 100px;
  padding-left: 3px;
  font-size: 13px;
  color: #666;
  margin-right: 20px;
}
.home-tab-adress {
  height: 25px;
  width: 25px;
  margin-right: 5px;
}
.business-bg {
  margin-top: 10px;
  background-color: #f3f3f3;
  padding: 15px;
  border-radius: 10px;
}

.business-title {
  color: #000;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 6px;
}
.business-min1 {
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 50px;
  background-color: #b1f2d7;
  color: #04c071;
  justify-content: space-between;
  margin-bottom: 10px;
    font-weight: 600;
}
.business-name {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
}
.business-min2 {
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 50px;
  background-color: #ffd79c;
  color: #fda928;
  margin-bottom: 10px;
  font-weight: 600;
  justify-content: space-between;
}
.business-min3 {
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 50px;
  background-color: #bdd7fc;
  color: #2f87ff;
  font-weight: 600;
  justify-content: space-between;
  margin-bottom: 10px;
}
.business-min-500{
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 50px;
  font-weight: 600;
  justify-content: space-between;
  flex: 1;
}
.box500{
  display: flex;
  justify-content: space-between;
}
.business-next {
  display: flex;
  align-items: center;
}
.business-icon {
  width: 10px;
  height: 10px;
  margin-right: 4px;
  margin-left: 7px;
}

.car-box {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.car-min {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 10px;
  background: repeating-linear-gradient(to left, #cbd7ef, #556efe);
  height: 70px;
  border-radius: 8px;
  padding-left: 20px;
  padding-right: 15px;
  flex: 1;
  color: #fff;
  font-size: 18px;
}
.car-min:nth-child(2n) {
  margin-right: 0px;
}
.car-icon {
  width: 30px;
  height: 40px;
}

.business-manage {
  text-align: center;
  color: #fff;
  font-size: 14px;
  padding-top: 25px;
  padding-bottom: 25px;
  background-image: url("https://wuyishangwu.wyssly.com/uploads/20230327/3ce3d35f72216c5fa19f29cc00c08d5c.png");
  background-size: 100% 100%;
  display: block;
}
.business-manage-half {
  text-align: center;
  color: #fff;
  font-size: 14px;
  padding-top: 25px;
  padding-bottom: 25px;
  margin-right: 10px;
  flex: 1;
  background-size: 100% 100%;
}
.bg1{
  background-image: url("https://wuyishangwu.wyssly.com/uploads/20230327/5e99a448d005c9075f3509c7a45a5bf3.png");
}

.bg2{
  background-image: url("https://wuyishangwu.wyssly.com/uploads/20230327/0a96c947b5ecdea8c26044858d2d8b55.png");
}

.bg3{
  background-image: url("https://wuyishangwu.wyssly.com/uploads/20230327/d8def8a22cb0bec1bcab11df52addb2c.png");
}

.bg4{
  background-image: url("https://wuyishangwu.wyssly.com/uploads/20230327/5f67f622fabc8c261f7302fae10a8b08.png");
}


.business-manage-half:nth-child(2n) {
  margin-right: 0px;
}

.business-manage-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 4px;
}
.business-manage-min {
  display: flex;
  margin-top: 10px;

}
.map-text{
  font-size: 16px;
  text-align: center;
}
</style>
